<template>
  <date-range-picker
    v-model="dateRangeData"
    :single-date-picker="singleDateRangePicker"
    :auto-apply="autoApply"
    :opens="opens"
    :ranges="customRanges"
    @update="updateRangeDate"
  >
    <template v-slot:input="picker">
      <span class="calendar-icon">
        <vs-icon icon="date_range"></vs-icon>
      </span>
      {{ formatDate(picker.startDate) }} ~ {{ formatDate(picker.endDate) }}
    </template>
  </date-range-picker>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker"; //you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";

export default {
  components: {
    DateRangePicker,
  },
  props: {
    dateRange: {
      type: Object,
      required: true,
    },
    singleDateRangePicker: {
      type: String,
      default: "range",
    },
    autoApply: {
      type: Boolean,
      default: true,
    },
    opens: {
      type: String,
      default: "right",
    },
    textFormat: {
      type: String,
      default: "MMM DD, YYYY"
    }
  },
  data() {
    return {
      dateRangeData: {
        startDate: "null",
        endDate: "null",
      },
      customRanges: {},
      moment: moment,
    };
  },
  methods: {
    formatDate(d) {
      return moment(d).format(this.textFormat);
    },
    updateRangeDate(value) {
      this.$emit("update", value);
    },
  },
  created() {
    this.dateRangeData = this.dateRange;

    const thisWeekStart = moment().startOf("week")._d;
    const thisWeekEnd = moment().endOf("week")._d;
    const thisMonthStart = moment().startOf("month")._d;
    const thisMonthEnd = moment().endOf("month")._d;
    const lastMonthStart = moment().subtract(1, "months").startOf("month")._d;
    const lastMonthEnd = moment().subtract(1, "months").endOf("month")._d;
    const thisYearStart = moment().startOf("year")._d;
    const thisYearEnd = moment().endOf("year")._d;
    const last12MonthStart = moment().subtract(12, "months").startOf("month")._d;

    this.customRanges = {
      "This week": [thisWeekStart, thisWeekEnd],
      "This month": [thisMonthStart, thisMonthEnd],
      "Last month": [lastMonthStart, lastMonthEnd],
      "This year": [thisYearStart, thisYearEnd],
      "Last 12 months": [last12MonthStart, moment()._d],
    };
  },
};
</script>
<style lang="css">
.daterangepicker {
  min-width: 390px !important;
}
.daterangepicker .calendar-table td.in-range {
  background-color: #edfff9;
}
.daterangepicker .calendar-table td.in-range.active {
  background-color: #074230;
}
.vue-daterange-picker {
  /* width: 280px; */
  width: 100%;
  display: block !important;
}
.vue-daterange-picker > .form-control {
  border: 1px solid #eaeaeb;
  border-radius: 6px;
  color: #7b8187;
  font-size: 0.9rem;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  padding: 6px;
}
.vue-daterange-picker .ranges li.active {
  background-color: #074230;
}
.calendar-icon .vs-icon {
  top: 2px;
  position: relative;
  padding-right: 5px;
}
</style>
